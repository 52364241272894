<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0">
      <!-- header -->
      <header-sheet />
    </v-container>

    <section class="py-12">
      <v-container class="mb-6 mb-xl-12">
        <v-row class="align-center mb-4" no-gutters>
          <v-col cols="12" sm="6" class="mb-4 mb-sm-0">
            <h1 class="text-h4 text-xl-h3 font-weight-black font-italic">
              GALERIA DE FOTOS
            </h1>
          </v-col>

          <v-spacer />

          <v-col cols="12" sm="6">
            <v-tabs
              active-class="overtext--text"
              slider-color="primary"
              background-color="transparent"
              right
            >
              <v-tab to="/gallery"> Destaques </v-tab>

              <v-tab to="/gallery/albums"> Albuns </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <router-view />
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>